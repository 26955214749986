import React, { useContext, useEffect } from "react";
import { Result, buildInteractiveResult } from "@coveo/headless";
import { useMutation } from "@tanstack/react-query";
import { PartCardV2 } from "../../../components/parts/PartCardV2";
import Button from "../../../components/common/buttons/Button";
import { leadTimeText } from "../../../helpers/LeadTimeHelper";
import { Status } from "../../../api/Parts/Types";
import {
  CurrencyRegion,
  CurrencySymbol,
} from "../../../pages/views/part_searches/Types";
import { SHIPMENT_DELAY_CONTACT_US_THRESHOLD_DAYS } from "../../../pages/views/parts/constants";
import { RegionContext } from "./RegionProvider";
import { quickAddToCart } from "../../../api/Cart/Service";
import { useEngineContext, EngineType } from "./EngineAndControllerProvider";

export interface PartResult extends Result {
  raw: Result["raw"] & {
    ec_images: string;
    part_number: string;
    local_price: number;
    shipment_delays?: number;
    part_status: string;
    entity_id: number;
    admin_only: number;
    hide_price?: string;
  };
  childResults: PartResult[];
}

export interface ResultCardProps {
  data: PartResult;
}

export const ResultCard: React.FC<ResultCardProps> = (props) => {
  const region = useContext(RegionContext);

  const searchEngine = useEngineContext(EngineType.Search);

  const interactiveResult = buildInteractiveResult(searchEngine, {
    options: { result: props.data },
  });

  useEffect(() => () => interactiveResult.cancelPendingSelect(), []);

  const addToCartMutation = useMutation({
    mutationFn: (partId: number) => {
      return new Promise((resolve, _reject) => {
        quickAddToCart(null, `/parts/${partId}/buy`, "Part", resolve);
      });
    },
  });

  const options = (
    <span className="text-secondary text-xs-regular p-0 m-0 h-[20px]">
      {props.data.childResults.length > 0
        ? `+ ${props.data.childResults.length} options`
        : ""}
    </span>
  );

  const {
    shipment_delays,
    part_status,
    ec_images,
    part_number,
    admin_only,
    local_price,
    hide_price,
    entity_id,
  } = props.data.raw;

  const leadTime = (
    <span
      className={`
      h-[20px]
      text-xs-regular
      ${[Status.IN_STOCK, Status.DEPLETE].includes(part_status as Status) ? "text-success-primary" : "text-brand-tertiary"}
    `}
    >
      {shipment_delays === null ||
      shipment_delays === undefined ||
      shipment_delays >= SHIPMENT_DELAY_CONTACT_US_THRESHOLD_DAYS
        ? ""
        : leadTimeText(shipment_delays, part_status)}
    </span>
  );

  // Coveo Commerce is not yet implemented,
  // so in the meanwhile, track just clicks.
  const onLinkClick = () => {
    interactiveResult.select();
  };
  const onAddToCart = () => {
    interactiveResult.select();
    addToCartMutation.mutate(entity_id);
  };
  const onSeeOptions = () => {
    interactiveResult.select();
    window.open(props.data.clickUri, "_blank");
  };

  const allowedToAddToCart =
    hide_price !== "true" &&
    props.data.childResults.length === 0 &&
    (shipment_delays ?? 0) < SHIPMENT_DELAY_CONTACT_US_THRESHOLD_DAYS;

  return (
    <PartCardV2
      onLinkClicked={onLinkClick}
      isSelectable={false}
      isSelected={false}
      part={{
        id: props.data.uniqueId,
        imagePath: ec_images,
        name: props.data.title,
        partNumber: part_number,
        fullUrl: props.data.clickUri,
        adminOnly: admin_only === 1,
        displayPriceRaw: {
          amount: local_price?.toString(),
          currencyCode: CurrencyRegion[region].toString(),
          currencySymbol: CurrencySymbol[region].toString(),
        },
        hidePrice: hide_price === "true",
      }}
      subtitleChildren={options}
      labelChildren={leadTime}
    >
      {allowedToAddToCart ? (
        <Button
          className="vention-ui-button--secondary-gray-outline self-center w-full mt-[16px]"
          onClick={onAddToCart}
          disabled={addToCartMutation.isPending}
        >
          <span className="text-sm-semibold">Add to cart</span>
        </Button>
      ) : (
        <Button
          className="vention-ui-button--secondary-gray-outline self-center w-full mt-[16px]"
          onClick={onSeeOptions}
        >
          <span className="text-sm-semibold">See Options</span>
        </Button>
      )}
    </PartCardV2>
  );
};
