import React from "react";
import GenericFormDownload from "../../components/GenericFormDownload";
import { User } from "../../../../../../api/Users/Types";
import { LocalizedPaths } from "../../../../../../types/Common";

interface Props {
  readonly currentUser: User;
  readonly captchaKey: string;
  readonly year: number;
  readonly explicitMarketingConsent?: boolean;
  readonly explicitTermsConsent?: boolean;
  readonly localizedPaths: LocalizedPaths;
}

const Page: React.FC<Props> = ({
  currentUser,
  captchaKey,
  year,
  explicitMarketingConsent,
  explicitTermsConsent,
  localizedPaths,
}) => {
  const formId = "diy-industrial-automation-report-form";
  const formActionPath = "/diy-industrial-automation-report";
  const localeName = "diy_industrial_automation_reports";

  return (
    <GenericFormDownload
      formId={formId}
      formActionPath={formActionPath}
      localeName={localeName}
      currentUser={currentUser}
      captchaKey={captchaKey}
      year={year}
      explicitMarketingConsent={explicitMarketingConsent}
      explicitTermsConsent={explicitTermsConsent}
      featuredImage="https://assets.vention.io/page-assets/diy-report/diy-industrial-automation-report-cover.jpg"
      featuredImageAlt="DIY Industrial Automation Report Cover"
      localizedPaths={localizedPaths}
    />
  );
};

export default Page;
