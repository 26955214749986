import React from "react";
import Button from "../common/buttons/Button";

export type PaginationProps = {
  currentPage?: number;
  lastPage?: number;
  moveToNext: () => void;
  moveToPrevious: () => void;
  isLoading: boolean;
};

export const Pagination: React.FC<PaginationProps> = (
  props: PaginationProps,
) => {
  if (props.isLoading || !props.currentPage || !props.lastPage) {
    return null; // Instead of showing a broken component, do not show anything
  }

  return (
    <>
      <div className="flex flex-row gap-1">
        <span className="text-md-regular mb-0">{"Page"}</span>
        <span className="text-md-semibold mb-0">
          {props.currentPage.toString()}
        </span>
        <span className="text-md-regular mb-0">{" of "}</span>
        <span className="text-md-semibold mb-0">
          {props.lastPage.toString()}
        </span>
      </div>
      <Button
        className="button-blank space-padding-left-2xl space-padding-top-none space-padding-bottom-none"
        disabled={props.currentPage === 1}
        onClick={props.moveToPrevious}
      >
        {<i className="ti ti-arrow-left icon-size-20px" />}
      </Button>
      <Button
        className="button-blank button-no-padding"
        disabled={props.currentPage === props.lastPage}
        onClick={props.moveToNext}
      >
        {<div className="ti ti-arrow-right icon-size-20px"></div>}
      </Button>
    </>
  );
};
