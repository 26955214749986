import React, { ReactNode } from "react";
import { PartCheckbox } from "./PartCheckbox";
import { PartDTO } from "../../api/Parts/dtos/PartDTO";
import { FormattedPrice } from "../../pages/views/parts/show/components/FormattedPrice";
import { Badge } from "@/components/ui/badge";

const DEFAULT_PART_IMAGE_URL =
  "https://assets.vention.io/png/generic_machine.png";
export type PartCardType = Pick<
  PartDTO,
  | "id"
  | "imagePath"
  | "name"
  | "displayPriceRaw"
  | "partNumber"
  | "fullUrl"
  | "adminOnly"
  | "hidePrice"
>;

export type PartCardV2Props = {
  readonly part: PartCardType;
  readonly onLinkClicked?: () => void;
  readonly children: ReactNode;
  readonly subtitleChildren?: ReactNode;
  readonly labelChildren?: ReactNode;
  readonly isSelectable: boolean;
  readonly isSelected: boolean;
  readonly onSelectClicked?: (part: Partial<PartDTO>) => void;
};

export const PartCardV2: React.FC<PartCardV2Props> = (
  props: PartCardV2Props,
) => {
  const {
    part,
    subtitleChildren,
    children,
    labelChildren,
    isSelectable,
    isSelected,
    onSelectClicked,
  } = props;

  const SelectCheckbox = () => {
    if (!isSelectable) {
      return null;
    }

    return (
      <PartCheckbox<Partial<PartDTO>>
        element={part}
        isSelected={isSelected}
        onSelectClicked={onSelectClicked}
      />
    );
  };
  return (
    <div className="rounded-md no-underline transition-[color] duration-200 ease-in flex flex-col flex-nowrap relative">
      {props.part.adminOnly && (
        <Badge
          variant="outline"
          className="text-sm-regular absolute m-2 z-[1] text-brand-700 bg-brand-50 border-brand-200"
        >
          Admin
        </Badge>
      )}
      <div className="relative overflow-hidden rounded-md bg-[#F9FAFB] transition-colors duration-200 max-w-full w-full pt-[100%] h-0">
        <a href={part.fullUrl} onClick={props.onLinkClicked}>
          <img
            className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 mix-blend-multiply block"
            src={part.imagePath}
            alt={part.name}
            loading="lazy"
            onError={(e) => {
              if (e.currentTarget.src !== DEFAULT_PART_IMAGE_URL) {
                e.currentTarget.src = DEFAULT_PART_IMAGE_URL;
              }
            }}
          />
        </a>
      </div>
      <SelectCheckbox />
      <div className="flex flex-col m-[8px] min-h-[172px] flex-grow">
        <div className="flex flex-col leading-5">
          <a
            href={part.fullUrl}
            className="no-underline transition-colors duration-200 flex flex-col"
            onClick={props.onLinkClicked}
          >
            <span className="text-xs-regular text-secondary">
              {part?.partNumber ?? ""}
            </span>

            <span className="hover:text-brand-secondary text-primary text-sm-medium h-[40px] line-clamp-2">
              {part?.name ?? ""}
            </span>
            {subtitleChildren}
          </a>
        </div>
        <div className="flex flex-grow" />

        <div className="flex flex-col mt-[16px]">
          {part.displayPriceRaw && !part.hidePrice && (
            <a
              href={part.fullUrl}
              className="no-underline"
              onClick={props.onLinkClicked}
            >
              <span className="text-black text-2xl m-0  product-viewer-v2__similar-price">
                <FormattedPrice
                  price={{
                    amount: part.displayPriceRaw.amount,
                    currency_code: part.displayPriceRaw.currencyCode,
                    currency_symbol: part.displayPriceRaw.currencySymbol,
                  }}
                />
              </span>
            </a>
          )}
          {labelChildren}
          {children}
        </div>
      </div>
    </div>
  );
};
