import { cn } from "@/lib/utils";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
  cloneElement,
} from "react";

type TreeViewElement = {
  id: string;
  name: string;
  isSelectable?: boolean;
  children?: TreeViewElement[];
};

type TreeContextProps = {
  selectedId: string | undefined;
  expendedItems: string[] | undefined;
  indicator: boolean;
  handleExpand: (id: string) => void;
  handleExpandOnly: (id: string) => void;
  selectItem: (id: string) => void;
  setExpendedItems?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  direction: "rtl" | "ltr";
};

const TreeContext = createContext<TreeContextProps | null>(null);

const useTree = () => {
  const context = useContext(TreeContext);
  if (!context) {
    throw new Error("useTree must be used within a TreeProvider");
  }
  return context;
};

export interface TreeViewComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {}

type Direction = "rtl" | "ltr" | undefined;

type TreeViewProps = {
  initialSelectedId?: string;
  indicator?: boolean;
  elements?: TreeViewElement[];
  initialExpendedItems?: string[];
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
} & TreeViewComponentProps;

/**
 * Tree View Docs: {@link: https://shadcn-extension.vercel.app/docs/tree-view}
 */

const Tree = forwardRef<HTMLDivElement, TreeViewProps>(
  (
    {
      className,
      elements,
      initialSelectedId,
      initialExpendedItems,
      children,
      indicator = true,
      openIcon,
      closeIcon,
      dir,
      ...props
    },
    ref,
  ) => {
    const [selectedId, setSelectedId] = useState<string | undefined>(
      initialSelectedId,
    );
    const [expendedItems, setExpendedItems] = useState<string[] | undefined>(
      initialExpendedItems,
    );

    const selectItem = useCallback((id: string) => {
      setSelectedId(id);
    }, []);

    const handleExpand = useCallback((id: string) => {
      setExpendedItems((prev) => {
        if (prev?.includes(id)) {
          return prev.filter((item) => item !== id);
        }
        return [...(prev ?? []), id];
      });
    }, []);

    const handleExpandOnly = useCallback((id: string) => {
      setExpendedItems([id]);
    }, []);

    const expandSpecificTargetedElements = useCallback(
      (elements?: TreeViewElement[], selectId?: string) => {
        if (!elements || !selectId) return;
        const findParent = (
          currentElement: TreeViewElement,
          currentPath: string[] = [],
        ) => {
          const isSelectable = currentElement.isSelectable ?? true;
          const newPath = [...currentPath, currentElement.id];
          if (currentElement.id === selectId) {
            if (isSelectable) {
              setExpendedItems((prev) => [...(prev ?? []), ...newPath]);
            } else {
              if (newPath.includes(currentElement.id)) {
                newPath.pop();
                setExpendedItems((prev) => [...(prev ?? []), ...newPath]);
              }
            }
            return;
          }
          if (
            isSelectable &&
            currentElement.children &&
            currentElement.children.length > 0
          ) {
            currentElement.children.forEach((child) => {
              findParent(child, newPath);
            });
          }
        };
        elements.forEach((element) => {
          findParent(element);
        });
      },
      [],
    );

    useEffect(() => {
      if (initialSelectedId) {
        expandSpecificTargetedElements(elements, initialSelectedId);
      }
    }, [initialSelectedId, elements]);

    useEffect(() => {
      if (initialExpendedItems) {
        setExpendedItems(initialExpendedItems);
      }
    }, [initialExpendedItems]);

    const direction = dir === "rtl" ? "rtl" : "ltr";

    return (
      <TreeContext.Provider
        value={{
          selectedId,
          expendedItems,
          handleExpand,
          selectItem,
          setExpendedItems,
          indicator,
          openIcon,
          closeIcon,
          direction,
          handleExpandOnly,
        }}
      >
        <div className={cn("relative px-2", className)}>
          <AccordionPrimitive.Root
            {...props}
            type="multiple"
            defaultValue={expendedItems}
            value={expendedItems}
            className="flex flex-col gap-1 overflow-visible"
            onValueChange={(value) =>
              setExpendedItems((prev) => [...(prev ?? []), value[0]])
            }
            dir={dir as Direction}
          >
            {children}
          </AccordionPrimitive.Root>
        </div>
      </TreeContext.Provider>
    );
  },
);

Tree.displayName = "Tree";

const TreeIndicator = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { direction } = useTree();

  return (
    <div
      dir={direction}
      ref={ref}
      className={cn(
        "h-full w-px bg-muted absolute left-1.5 rtl:right-1.5 py-3 rounded-md hover:bg-slate-300 bg-slate-200 duration-300 ease-in-out",
        className,
      )}
      {...props}
    />
  );
});

TreeIndicator.displayName = "TreeIndicator";

interface IconComponentProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> {}

type IconedElementProps = {
  expendedItems?: string[];
  element: string;
  isSelectable?: boolean;
  isSelect?: boolean;
  useIcon?: boolean;
  iconRight?: boolean;
  onTextClicked?: () => void | Promise<void>;
} & IconComponentProps;

const IconedElement = forwardRef<
  { onIconClicked: () => void | Promise<void> },
  IconedElementProps & React.HTMLAttributes<HTMLDivElement>
>(
  (
    {
      className,
      element,
      value,
      isSelectable = true,
      isSelect,
      children,
      useIcon,
      iconRight,
      onTextClicked,
      ...props
    },
    ref,
  ) => {
    const {
      direction,
      handleExpand,
      handleExpandOnly,
      expendedItems,
      indicator,
      setExpendedItems,
      openIcon,
      closeIcon,
    } = useTree();

    const handleOnClickTitle = () => {
      onTextClicked?.();
      if (!expendedItems?.includes(value) && children !== undefined) {
        handleExpandOnly(value);
      }
    };

    const titleClassName = isSelect ? "text-sm-semibold" : "text-sm-regular";

    return (
      <AccordionPrimitive.Item
        {...props}
        value={value}
        className="relative overflow-hidden h-full w-full"
      >
        <AccordionPrimitive.Header
          className={cn(
            `flex justify-between rounded-md flex-start text-left py-2 px-1 bg-white m-0 w-full`,
            className,
            {
              "cursor-default": isSelectable,
              "cursor-not-allowed opacity-50": !isSelectable,
            },
          )}
        >
          {!iconRight ? (
            <button
              onClick={handleOnClickTitle}
              className="bg-white border-0 p-0 m-0 hover:bg-white"
            >
              <span className={cn("text-start line-clamp-1", titleClassName)}>
                {element}
              </span>
            </button>
          ) : null}

          {useIcon === true
            ? expendedItems?.includes(value)
              ? (cloneElement(openIcon, {
                  onClick: () => handleExpand(value),
                }) ?? <></>)
              : cloneElement(closeIcon, { onClick: () => handleExpand(value) })
            : null}

          {iconRight ? (
            <button
              onClick={handleOnClickTitle}
              className="bg-white border-0 p-0 m-0 hover:bg-white"
            >
              <span className={cn("text-start line-clamp-1", titleClassName)}>
                {element}
              </span>
            </button>
          ) : null}
        </AccordionPrimitive.Header>
        {children && (
          <AccordionPrimitive.Content className=" data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down relative overflow-hidden h-full">
            {element && indicator && <TreeIndicator aria-hidden="true" />}
            <AccordionPrimitive.Root
              dir={direction}
              type="multiple"
              className="flex flex-col ml-3 rtl:mr-3 "
              defaultValue={expendedItems}
              value={expendedItems}
              onValueChange={(value) => {
                setExpendedItems?.((prev) => [...(prev ?? []), value[0]]);
              }}
            >
              {children}
            </AccordionPrimitive.Root>
          </AccordionPrimitive.Content>
        )}
      </AccordionPrimitive.Item>
    );
  },
);

IconedElement.displayName = "IconedElementItem";

export { Tree, IconedElement, type TreeViewElement };
