import React from "react";
import { Facet as FacetController, FacetValue } from "@coveo/headless";
import { useEffect, useState } from "react";
import { TreeViewElement } from "@/components/ui/tree-view-api";
import { forOwnRight } from "lodash";
import { TreeMenuView } from "./TreeMenuView";
import { useControllerState } from "../../utils/useControllerState";
import { PartCategoryByCode } from "../../../pages/views/part_searches/Types";

export interface CategoryFacetProps {
  controller: FacetController;
  labels: PartCategoryByCode;
  title: string;
}

const lastCategoryCodeInHeigharchy = (category: string) =>
  category.split("|").pop();

export const latestPartCategoryByCode = (
  category: string,
  labels: PartCategoryByCode,
) => {
  const latestCategoryCode = lastCategoryCodeInHeigharchy(category);

  if (latestCategoryCode && labels[latestCategoryCode]) {
    return labels[latestCategoryCode];
  }

  // If the category is not found, return a default object
  return {
    name: latestCategoryCode || "",
    description: "",
  };
};

const groupByParentCategory = (
  facetValues: FacetValue[],
): Record<string, FacetValue[]> => {
  const groupedCategories: Record<string, FacetValue[]> = {};

  facetValues.forEach((facetValue) => {
    const parentCategory = facetValue.value.split("|")[0];

    if (groupedCategories[parentCategory] === undefined) {
      groupedCategories[parentCategory] = [facetValue];
    } else {
      groupedCategories[parentCategory].push(facetValue);
    }
  });

  return groupedCategories;
};

const toTreeViewElements = (
  groupedValue: Record<string, FacetValue[]>,
  labels: PartCategoryByCode,
  isValueSelected: (value: FacetValue) => boolean,
  isAllSelected,
): (TreeViewElement & { facet: FacetValue; isSelected?: boolean })[] => {
  const tree: (TreeViewElement & {
    facet: FacetValue;
    isSelected?: boolean;
  })[] = [];
  forOwnRight(groupedValue, (value: FacetValue[], key: string) => {
    const groupElement: TreeViewElement & {
      facet: FacetValue;
      isSelected?: boolean;
    } = {
      id: key,
      name: `${latestPartCategoryByCode(key, labels).name} (${value[0].numberOfResults})`,
      isSelectable: true,
      isSelected: isValueSelected(value[0]),
      facet: value[0],
      children: value
        .slice(1)
        .map<
          TreeViewElement & { facet: FacetValue; isSelected?: boolean }
        >((val: FacetValue) => {
          const category = latestPartCategoryByCode(val.value, labels);

          return {
            id: val.value,
            name: `${category.name} (${val.numberOfResults})`,
            isSelectable: true,
            facet: val,
            isSelected: isValueSelected(val),
          };
        }),
    };
    tree.push(groupElement);
  });

  tree.sort((a, b) => a.name.localeCompare(b.name));

  tree.unshift({
    id: "all-root",
    name: "All categories",
    isSelectable: true,
    isSelected: isAllSelected,
    facet: undefined as any,
  });
  return tree;
};

const extractParentCategory = (facetValue: FacetValue) => {
  return facetValue.value.split("|")[0];
};

const extractSubCategory = (facetValue: FacetValue) => {
  return facetValue.value.split("-")[1];
};

export const CategoryFacet: React.FC<CategoryFacetProps> = (props) => {
  const { controller, title } = props;
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [facetsInitializedFromUrlPath, setFacetsInitializedFromUrlPath] =
    useState<boolean>(false);

  const state = useControllerState(controller);

  useEffect(() => {
    // at least one group or subgroup is selected in the controller
    setIsAllSelected(
      !state.values.some((facet) => controller.isValueSelected(facet)),
    );
  }, [state]);

  const selectedFacetValue = state.values.find(
    (value) => value.state === "selected",
  );

  const syncUrlAnchorWithSelectedFacets = () => {
    const url = new URL(window.location.href);

    // selectedFacetValue: "IP|IP-NP"
    const parentCategory =
      selectedFacetValue && extractParentCategory(selectedFacetValue);
    const subCategory =
      selectedFacetValue && extractSubCategory(selectedFacetValue);

    if (parentCategory) {
      url.searchParams.set("category", parentCategory);
    } else {
      url.searchParams.delete("category");
    }

    if (subCategory) {
      url.searchParams.set("sub_category", subCategory);
    } else {
      url.searchParams.delete("sub_category");
    }

    window.history.replaceState({}, "", url);
  };

  const initializeFacetsFromUrlPath = () => {
    const url = new URL(window.location.href);

    const parentCategoryParams = url.searchParams.get("category");
    const subCategoryParam = url.searchParams.get("sub_category");

    if (parentCategoryParams && subCategoryParam) {
      const matchingFacetOption = state.values.find((facetValue) => {
        return (
          extractParentCategory(facetValue) === parentCategoryParams &&
          extractSubCategory(facetValue) === subCategoryParam
        );
      });

      if (matchingFacetOption && matchingFacetOption?.state !== "selected") {
        controller.toggleSingleSelect(matchingFacetOption);
      }
    } else if (parentCategoryParams) {
      const matchingFacetOption = state.values.find((facetValue) => {
        return extractParentCategory(facetValue) === parentCategoryParams;
      });

      if (matchingFacetOption && matchingFacetOption?.state !== "selected") {
        controller.toggleSingleSelect(matchingFacetOption);
      }
    }
  };

  useEffect(() => {
    if (!state.values.length) return;

    if (facetsInitializedFromUrlPath) {
      syncUrlAnchorWithSelectedFacets();
    } else {
      initializeFacetsFromUrlPath();

      setFacetsInitializedFromUrlPath(true);
    }
  }, [state.values.length, selectedFacetValue, facetsInitializedFromUrlPath]);

  if (!state.values.length) {
    return null;
  }

  const facetValuesGroupedByParent = groupByParentCategory(state.values);

  const treeViewElements = toTreeViewElements(
    facetValuesGroupedByParent,
    props.labels,
    controller.isValueSelected,
    isAllSelected,
  );

  const handleOnValueSelected = (
    value: TreeViewElement & { facet: FacetValue; isSelected?: boolean },
  ) => {
    if (value.facet && !controller.isValueSelected(value?.facet)) {
      controller.toggleSingleSelect(value?.facet);
    } else {
      controller.deselectAll();
    }
  };

  return (
    <div className="min-w-56">
      <span className="text-start text-md-medium text-primary flex flex-row bg-white justify-between w-full py-2 px-1 border-0 border-b border-border-secondary border-solid mt-2 mb-4">
        {title}
      </span>
      <TreeMenuView
        initialExpendedItems={treeViewElements
          ?.slice(1)
          ?.filter(
            (element) => controller.isValueSelected(element.facet) === true,
          )
          .map((element) => element.id)}
        elements={treeViewElements}
        onClickElement={(element: any) => {
          handleOnValueSelected(element);
        }}
      />
    </div>
  );
};
