import React, { CSSProperties } from "react";
import {
  ControllerType,
  useControllerContext,
} from "../../../../coveo/components/part_library/EngineAndControllerProvider";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { PartCategoryByCode } from "../Types";
import { cn } from "@/lib/utils";
import { latestPartCategoryByCode } from "../../../../coveo/components/part_library/CategoryFacet";
import { IconChevronRight } from "@tabler/icons-react";

interface BannerProps {
  partCategoryByCode: PartCategoryByCode;
}

export const Banner: React.FC<BannerProps> = ({ partCategoryByCode }) => {
  const facetController = useControllerContext(ControllerType.CategoryFacet);
  const queryController = useControllerContext(ControllerType.QuerySummary);
  const faceState = useControllerState(facetController);
  const queryState = useControllerState(queryController);

  // If there is a search, don't show the banner
  if (queryState.hasQuery) {
    return null;
  }

  let title = "Industrial Automation Parts and Equipment";
  let description = (
    <a
      href="/new-design"
      className="no-underline inline-flex items-center gap-2 text-sm-bold text-gray-500"
    >
      Create a Design <IconChevronRight />
    </a>
  );
  let style: CSSProperties = {
    backgroundImage:
      "url('https://assets.vention.io/images/parts-library/banner-bg-pattern.png')",
    backgroundSize: "contain",
  };

  // Get the selected category code (subcategories are separated by "|")
  const selectedCategoryCode = faceState.values.find(
    (value) => value.state === "selected",
  );

  const category = selectedCategoryCode
    ? latestPartCategoryByCode(selectedCategoryCode?.value, partCategoryByCode)
    : null;

  if (category) {
    title = category.name;
    description = <>{category.description}</>;
    style = {};
  }

  return (
    <div
      className={cn(
        "p-8 text-center bg-bg-brand-primary rounded-lg flex flex-col",
      )}
      style={style}
    >
      <span className="text-display-xs-medium mt-0 mb-1">{title}</span>
      {description && (
        <span className="text-sm-medium text-secondary">{description}</span>
      )}
    </div>
  );
};
