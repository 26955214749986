import React, { useEffect, useState } from 'react'
import { User } from '../../api/Users/Types'
import ProjectTitanOnboardingProfileSetUp, { UserInfoFields } from './ProjectTitanOnboardingProfileSetup'

interface Props {
  readonly profile: User
  readonly goToCreateOrJoin: () => void
  readonly user: UserInfoFields
  readonly setUser: React.Dispatch<React.SetStateAction<UserInfoFields>>
  readonly createUserProfile: () => Promise<boolean>
  readonly setEmailMarketingConsent: React.Dispatch<React.SetStateAction<boolean | undefined>>
  readonly emailMarketingConsent?: boolean
  readonly errors?: { [key: string]: string }
}

const OnboardingProfileSetUpWrapper: React.FunctionComponent<Props> = ({
  profile,
  goToCreateOrJoin,
  user,
  setUser,
  createUserProfile,
  setEmailMarketingConsent,
  emailMarketingConsent,
  errors,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    GoogleAnalyticsHelper.VentionGAEvent(
      'user_email_confirmed',
      'user_email_confirmed',
      'user_conversion',
      profile.id
    )
  }, [])

  const handleProfileSetupSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)

    const pass = await createUserProfile()

    // if successful, do not re-enable the button,
    // instead, let the step or page change
    if (!pass) {
      setIsLoading(false)
      return // skip if there was an error during user profile activation
    }

    goToCreateOrJoin()
  }

  const handleFirstNameChange = event => {
    setUser({ ...user, firstName: event.target.value })
  }

  const handleLastNameChange = event => {
    setUser({ ...user, lastName: event.target.value })
  }

  const handlePhoneChange = phone => {
    setUser({ ...user, phone: phone })
  }

  const handleRoleChange = event => {
    setUser({ ...user, role: event.target.value })
  }

  const handlePasswordChange = event => {
    setUser({ ...user, password: event.target.value })
  }

  return (
    <ProjectTitanOnboardingProfileSetUp
      handleProfileSetupSubmit={handleProfileSetupSubmit}
      handleFirstNameChange={handleFirstNameChange}
      handleLastNameChange={handleLastNameChange}
      handlePhoneChange={handlePhoneChange}
      handleRoleChange={handleRoleChange}
      handlePasswordChange={handlePasswordChange}
      user={user}
      profile={profile}
      setEmailMarketingConsent={setEmailMarketingConsent}
      emailMarketingConsent={emailMarketingConsent}
      isLoading={isLoading}
      errors={errors}
    />
  )
}

export default OnboardingProfileSetUpWrapper
