import React from "react";
import GenericFormDownload from "../../components/GenericFormDownload";
import { User } from "../../../../../../api/Users/Types";
import { LocalizedPaths } from "../../../../../../types/Common";

interface Props {
  readonly currentUser: User;
  readonly captchaKey: string;
  readonly explicitMarketingConsent?: boolean;
  readonly explicitTermsConsent?: boolean;
  readonly localizedPaths: LocalizedPaths;
}

const Page: React.FC<Props> = ({
  currentUser,
  captchaKey,
  explicitMarketingConsent,
  explicitTermsConsent,
  localizedPaths,
}) => {
  const formId = "palletizer-buyers-guide-form";
  const formActionPath = "/palletizer-buyers-guide";
  const localeName = "palletizer_buyers_guides";

  return (
    <GenericFormDownload
      formId={formId}
      formActionPath={formActionPath}
      localeName={localeName}
      currentUser={currentUser}
      captchaKey={captchaKey}
      explicitMarketingConsent={explicitMarketingConsent}
      explicitTermsConsent={explicitTermsConsent}
      featuredImage="https://assets.vention.io/page-assets/palletizer-buyers-guides/palletizing-automation-buyers-guide.jpg"
      featuredImageSuccess="https://assets.vention.io/page-assets/palletizer-buyers-guides/palletizing-automation-buyers-guide-2.jpg"
      featuredImageAlt="Palletizing Automation Buyer's Guide Cover"
      localizedPaths={localizedPaths}
    />
  );
};

export default Page;
