import React from "react";

export const EmptySimplePartCard: React.FC = () => {

  return (
    <div className="simple-card__empty-container">

      <div className="simple-card__empty-container__image">
        <i className='ti ti-plus icon-size-20px simple-card__empty-container__image__icon' />
      </div>
      <span className="space-margin-top-sm text-tertiary text-xs-medium">Add Part</span>

    </div>
  )
}