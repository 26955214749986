import React from "react";
import { ShowPart, ShowPartVariantOption } from "../Page";
import ReadMoreText from "../../../../../components/vention_ui/shared_components/ReadMoreText";

interface Props {
  readonly product: ShowPart;
  readonly productVariantOptions: ReadonlyArray<ShowPartVariantOption>;
}

const onProductVariantOptionChange = (event) => {
  window.location.href = `/parts/${event.target.value}`;
};

const Header: React.FC<Props> = ({ product, productVariantOptions }) => {
  const currentVariantOption = productVariantOptions.find(
    (variantOption) => variantOption.id === product.id,
  );

  return (
    <>
      <p className="product-viewer-v2__header-number flex gap-3">
        {product.part_number}
        {product.is_light_duty && (
          <span className="text-xs-regular text-primary bg-gray-100 px-2 py-1 rounded-3xl text-sm">
            Light duty part
          </span>
        )}
      </p>
      <h1 className="product-viewer-v2__header-name">{product.name}</h1>

      <span className="text-sm-semibold text-blue-600 mb-4">
        {product.brand || "Vention"}
      </span>

      {productVariantOptions.length > 0 && (
        <div className="product-viewer-v2__header-dropdown">
          <label className="product-viewer-v2__header-dropdown-label">
            Option
          </label>
          <div className="product-viewer-v2__header-dropdown-select-wrapper">
            <select
              onChange={onProductVariantOptionChange}
              defaultValue={currentVariantOption?.slug}
            >
              {productVariantOptions.map((option) => (
                <option key={option.id} value={option.slug}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <div className="product-viewer-v2__header-description">
        <ReadMoreText lines={4} readMoreText="See more" readLessText="See less">
          {product.description}
        </ReadMoreText>
      </div>
    </>
  );
};

export default Header;
