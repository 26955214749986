import { useContext, useEffect, useState } from "react";
import { ControllerType, EngineType, useControllerContext, useEngineContext } from "../../components/part_library/EngineAndControllerProvider";
import { useControllerState } from "../../utils/useControllerState";

export const useNoResults = () => {
  const resultListController = useControllerContext(ControllerType.ResultList);
  const resultListState = useControllerState(resultListController);
  const engine = useEngineContext(EngineType.Search);
  const [engineState, setEngineState] = useState(engine.state);

  useEffect(() => engine.subscribe(() => setEngineState(engine.state)), [engine]);

  // If there are no results, the request has been made, and the request is not loading, then there are no results
  // We want to distinguish between no results and no request made
  return resultListState.results.length == 0 && engineState.search.requestId != "" && !engineState.search.isLoading;
};