import React, { FC } from "react";
import { FeaturedPartCategoryDTO } from "../../../api/Parts/dtos/FeaturedPartCategory.DTO";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useControllerState } from "../../utils/useControllerState";

export type FeaturedPartCategoryListProps = {
  featuredPartCategories?: FeaturedPartCategoryDTO[];
  isLoading: boolean;
};

export const FeaturedPartCategoryList: FC<FeaturedPartCategoryListProps> = (
  props: FeaturedPartCategoryListProps,
) => {
  const categoryController = useControllerContext(ControllerType.CategoryFacet);
  const categoryState = useControllerState(categoryController);

  const handleCategoryClick = (category: FeaturedPartCategoryDTO) => {
    const fullCode = category.partCategory.parent
      ? `${category.partCategory.parent.code}|${category.partCategory.parent.code}-${category.partCategory.code}`
      : category.partCategory.code;

    const categoryFacet = categoryState.values.find(
      (facet) => facet.value === fullCode,
    );

    if (categoryFacet) {
      categoryController.toggleSelect(categoryFacet);
    } else {
      categoryController.toggleSelect({
        value: category.partCategory.code,
        state: "selected",
        numberOfResults: 0,
      });
    }
  };

  if (!props.isLoading && !props.featuredPartCategories?.length) {
    return null;
  }

  return (
    <div className="mt-[32px] flex flex-col">
      <span className="text-md-semibold pb-[16px]">Featured Categories</span>
      <div className="grid grid-cols-7 max-md:grid-cols-4 max-sm:grid-cols-2 gap-xl">
        {!props.isLoading &&
          props?.featuredPartCategories?.map((featuredPartCategory) => (
            <button
              onClick={() => handleCategoryClick(featuredPartCategory)}
              key={featuredPartCategory.id}
              className="flex flex-col h-fit mx-0 mb-0 rounded-full justify-center items-center bg-transparent border-none mt-[16px] p-0"
            >
              <img
                className="max-w-[200px] max-h-[200px] min-w-[100px] min-h-[100px] w-full h-full aspect-square rounded-full border-2 border-solid border-slate-200 hover:shadow-custom-hover"
                src={
                  featuredPartCategory?.partCategory?.imageUrl ??
                  "https://assets.vention.io/png/generic_machine.png"
                }
                alt={featuredPartCategory.partCategory.name}
              />

              <span className="self-center text-sm-semibold my-[16px]">
                {featuredPartCategory.partCategory.name}
              </span>
            </button>
          ))}
        {props.isLoading &&
          [1, 2, 3, 4, 5, 6, 7].map((index) => (
            <div
              key={index}
              className="flex flex-col  h-fit mx-0 mb-0 justify-center items-center bg-transparent border-none mt-[16px] p-0"
            >
              <div className="max-w-[200px] max-h-[200px] min-w-[100px] min-h-[100px] w-full h-full aspect-square rounded-full bg-lazy-load" />
              <div className="w-16 h-5 self-center my-[16px] bg-lazy-load rounded-sm" />
            </div>
          ))}
      </div>
    </div>
  );
};
