import React from "react";
import { AutoComplete } from "../../../partials/global/global-search/AutoComplete";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useControllerState } from "../../utils/useControllerState";

const SearchBox: React.FC = () => {
  const controller = useControllerContext(ControllerType.SearchBox);
  const state = useControllerState(controller);

  const onSearchSubmit = (value: string) => {
    controller.updateText(value);
    controller.submit();
  };

  return (
    <AutoComplete
      value={state.value}
      onChange={(value) => controller.updateText(value)}
      suggestions={state.suggestions.map((item) => item.rawValue)}
      onSubmit={onSearchSubmit}
      variant="full-width"
      placeholder="Search parts"
    />
  );
};

export default SearchBox;
