import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import TechnicalDocuments from "./components/TechnicalDocuments";
import Specifications from "./components/Specifications";
import WhatsIncluded from "./components/WhatsIncluded";
import Faqs from "./components/Faqs";
import { Cart } from "./components/Cart";
import { ImageGallery } from "./components/ImageGallery";
import { useQueryPaginationGQL } from "../../../../hooks/usePaginationGQL";
import {
  PartService,
  QueryFullPartsByIdsParams,
  QuerySimilarPartsParams,
} from "../../../../api/Parts/PartsService";
import { PartSkeletonList } from "../../../../components/parts/PartSkeletonList";
import SimilarParts from "./components/SimilarPart";
import { addOnePartToCart } from "../../../../api/Cart/Service";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
} from "@tanstack/react-query";
import { SimilarPartDTO } from "../../../../api/Parts/dtos/SimilarPartDTO";
import { useModal } from "../../../../hooks/useModal";
import { CompareGadget } from "../show/components/CompareGadget";
import { PartDTO } from "../../../../api/Parts/dtos/PartDTO";
import { CompareModal } from "./components/CompareModal";
import { uniq } from "lodash";
import { Breadcrumbs } from "@/components/ui/breadcrumb";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import {
  PartCollectionDTO,
  Status as PartStatus,
  SimilarPartCollectionDTO,
} from "../../../../api/Parts/Types";

export interface ShowPart {
  readonly id: number;
  readonly name: string;
  readonly part_number: string;
  readonly description: string;
  readonly is_light_duty: boolean;
  readonly weight?: number;
  readonly length?: number;
  readonly width?: number;
  readonly height?: number;
  readonly hide_price: boolean;
  readonly material: string;
  readonly surface_finish: string;
  readonly applicable_standards: string;
  readonly vention_comment: string;
  readonly is_software: boolean;
  readonly part_category: ShowPartCategory & {
    parent: ShowPartCategory;
  };
  readonly image_path: string;
  readonly certification_logos: ReadonlyArray<ShowCertificationLogo>;
  readonly additional_image: {
    readonly path: string;
    readonly className: string;
  };
  readonly status_availability: ShowPartStatusAvailability;
  readonly display_price: string;
  readonly display_price_raw?: {
    amount: string;
    currency_symbol: string;
    currency_code: string;
  };
  readonly shipment_delay_weeks: number;
  readonly canonical_url: string;
  readonly allow_checkout: boolean;
  readonly pdf_availability: boolean;
  readonly step_availability: boolean;
  readonly hide_drawing_2d_file: boolean;
  readonly published_technical_documents: ReadonlyArray<ShowTechnicalDocument>;
  readonly legacy_technical_documents: ReadonlyArray<ShowLegacyTechnicalDocument>;
  readonly associated_fasteners: ReadonlyArray<ShowFastener>;
  readonly light_duty_fasteners: ReadonlyArray<ShowFastener>;
  readonly part_documents: ReadonlyArray<ShowPartDocument>;
  readonly calculators: ReadonlyArray<ShowCalculator>;
  readonly technical_specifications: ReadonlyArray<ShowTechnicalSpecs>;
  readonly part_images: ReadonlyArray<PartImage>;
  readonly sold_by?: string;
  readonly ship_from?: string;
  readonly brand?: string;
  readonly is_robot?: boolean;
}

interface PartImage {
  readonly id: number;
  readonly image: { url: string; thumb: { url: string } };
}

interface ShowPartStatusAvailability {
  readonly className: string;
  readonly text: string;
}
interface ShowPartCategory {
  readonly name: string;
  readonly url: string;
  readonly id: number;
}

interface ShowTechnicalDocument {
  readonly url: string;
  readonly id: number;
  readonly title: string;
}

interface ShowLegacyTechnicalDocument {
  readonly name: string;
  readonly path: string;
}

interface ShowPartDocument {
  readonly title: string;
  readonly file_url: string;
}

interface ShowCalculator {
  readonly name: string;
  readonly url: string;
  readonly analytic_id: string;
  readonly description: string;
}

interface ShowTechnicalSpecs {
  readonly label: string;
  readonly properties: ReadonlyArray<ShowTechnicalSpecsProperties>;
}

interface ShowTechnicalSpecsProperties {
  readonly label: string;
  readonly unit: string;
  readonly value: string;
  readonly spec_name: string;
}

interface ShowCertificationLogo {
  readonly id: number;
  readonly image_url: string;
  readonly name: string;
}

export interface ShowFastener {
  readonly child_id: number;
  readonly child_repeat_count: number;
  readonly part: {
    readonly part_number: string;
  };
}

export interface ShowPartVariantOption {
  readonly id: number;
  readonly label: string;
  readonly order: number;
  readonly slug: string;
}

export interface ShowIncludedPartOrFastener {
  readonly name: string;
  readonly part_number: string;
  readonly quantity: number;
  readonly part_url?: string;
  readonly thumbnail_image_path: string;
}

export interface ShowRecommendedPartSearch {
  readonly part_name: string;
  readonly shipment_delay_weeks: number;
  readonly status_availability: ShowPartStatusAvailability;
  readonly display_price: string;
  readonly image_path: string;
  readonly full_url: string;
}

interface Props {
  readonly part: ShowPart;
  readonly partVariantsOptions: ReadonlyArray<ShowPartVariantOption>;
  readonly includedPartsAndFasteners: ReadonlyArray<ShowIncludedPartOrFastener>;
  readonly geographicalRegion: {
    code: string;
    created_at: string;
    exchange_rate_id: number;
    id: number;
    name: string;
    updated_at: string;
  };
}

const queryClient = new QueryClient();
const Page: React.FC<Props> = ({
  part,
  partVariantsOptions,
  includedPartsAndFasteners,
  geographicalRegion,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <PagePartsV2
        part={part}
        partVariantsOptions={partVariantsOptions}
        includedPartsAndFasteners={includedPartsAndFasteners}
        geographicalRegion={geographicalRegion}
      />
    </QueryClientProvider>
  );
};

const PagePartsV2: React.FC<Props> = ({
  part,
  partVariantsOptions,
  includedPartsAndFasteners,
  geographicalRegion,
}) => {
  const [selectedPartsToCompare, setSelectedPartsToCompare] = useState<
    SimilarPartDTO[]
  >([]);

  const [allPropertyLabels, setAllPropertiesLabels] = useState<
    Record<string, string[]>
  >({});

  const {
    isOpen: isComparingParts,
    onToggle: handleToggleComparingParts,
    onClose: handleCloseComparingParts,
  } = useModal();

  const {
    isOpen: isShowComparePartsModal,
    onOpen: handleOpenCompareParts,
    onClose: handleCloseComparePartsModal,
  } = useModal();

  const resultSimilarParts = useQueryPaginationGQL<
    SimilarPartCollectionDTO,
    "parts",
    QuerySimilarPartsParams
  >(
    "parts",
    "SimilarParts",
    [
      part?.id,
      part?.part_number,
      [PartStatus.DEPLETE, PartStatus.IN_STOCK],
      part?.is_robot === true
        ? [part?.part_category?.parent?.id, part?.part_category?.id]
        : [part?.part_category?.id],
      part?.is_robot === true ? true : undefined,
      geographicalRegion?.id,
    ],
    1,
    8,
    PartService.querySimilarParts,
  );

  const resutlFullCompareParts = useQueryPaginationGQL<
    PartCollectionDTO,
    "parts",
    QueryFullPartsByIdsParams
  >(
    "parts",
    "FullParts",
    [
      [`${part.id}`, ...selectedPartsToCompare.map((part) => part.id)],
      geographicalRegion?.id,
    ],
    1,
    4,
    PartService.queryFullPartsByIds,
    selectedPartsToCompare.length > 0 && isShowComparePartsModal,
  );

  const {
    data: similarParts,
    isLoading: isLoadingSimilarParts,
    isError: isErrorSimilarParts,
  } = resultSimilarParts.result;

  const [isLoadingAddPartToCart, setIsLoadingAddPartToCart] = useState<{
    [key: string]: boolean;
  }>({});

  const { mutateAsync: addPartToCart } = useMutation(
    {
      mutationFn: async (similarPartId: string) => {
        setIsLoadingAddPartToCart({
          ...isLoadingAddPartToCart,
          [`${similarPartId}`]: true,
        });
        const result = await new Promise((resolve) => {
          addOnePartToCart(similarPartId, (response: unknown) => {
            resolve(response);
            setIsLoadingAddPartToCart({
              ...isLoadingAddPartToCart,
              [`${similarPartId}`]: false,
            });
          });
        });

        return result;
      },
    },
    new QueryClient(),
  );

  useEffect(() => {
    if (
      (resutlFullCompareParts.result?.data?.parts?.collection?.length ?? 0) > 0
    ) {
      const propertiesLabels: Record<string, string[]> | undefined =
        resutlFullCompareParts.result.data?.parts.collection
          ?.flatMap((part) => part.technicalSpecifications)
          .reduce<Record<string, string[]>>((acc, cur) => {
            if (!acc[cur.label]) {
              acc[cur.label] = cur.properties.map((property) => property.label); //create first one
            } else {
              acc[cur.label] = uniq([
                ...acc[cur.label],
                ...cur.properties.map((property) => property.label),
              ]);
            }
            return acc;
          }, {});
      setAllPropertiesLabels(propertiesLabels ?? {});
      // Sort API result, no need to create start
      resutlFullCompareParts.result?.data?.parts?.collection?.sort((a, _) =>
        a.id === `${part.id}` ? -1 : 1,
      );
    }
  }, [resutlFullCompareParts?.result?.data?.parts?.collection]);

  const breadCrumbs = [
    {
      label: "Parts Library",
      link: "/parts-library",
    },
  ];

  const { part_category, canonical_url } = part;

  if (part_category) {
    breadCrumbs.push({
      label: part_category.parent.name,
      link: `/parts-library/${part_category.parent.url}`,
    });
    breadCrumbs.push({
      label: part_category.name,
      link: `/parts-library/${part_category.url}`,
    });
  }

  breadCrumbs.push({
    label: "Product Details",
    link: canonical_url,
  });

  const handleAddSimilarPartToCart = async (similarPartId: string) => {
    await addPartToCart(similarPartId);
  };

  const handleNavigateSimilarPartsToNextPage = () => {
    resultSimilarParts.pagePlusOne();
  };

  const handleNavigateSimilarPartsToPreviousPage = () => {
    resultSimilarParts.pageMinusOne();
  };

  const handleOnComparingParts = () => {
    handleToggleComparingParts();
  };

  const handleOnSelectPartToCompare = (part: Partial<PartDTO>) => {
    if (selectedPartsToCompare.includes(part as SimilarPartDTO)) {
      setSelectedPartsToCompare(
        selectedPartsToCompare.filter((element) => element.id !== part.id),
      );
    } else if (selectedPartsToCompare.length >= 3) {
      // show toast
      toastr.info("Only 4 parts can be selected for comparing");
    } else {
      setSelectedPartsToCompare([
        ...selectedPartsToCompare,
        part as SimilarPartDTO,
      ]);
    }
  };

  const handleOnCleanComparingParts = () => {
    setSelectedPartsToCompare([]);
  };

  const handleCompare = () => {
    handleOpenCompareParts();
  };

  const hasSimilarParts = (similarParts?.parts?.collection?.length ?? 0) > 0;

  const SimilarPartComponent = () => {
    if (isLoadingSimilarParts) {
      return <PartSkeletonList count={8} />;
    }

    if (isErrorSimilarParts || (similarParts?.parts?.errors?.length ?? 0) > 0) {
      return <></>; // nothing to be displayed at the moment when error occur
    }

    if (!hasSimilarParts) {
      return <></>;
    }

    return (
      <section
        id="part-recommendations"
        className="product-viewer-v2__part-recommendations product-viewer-v2__section space-padding-lg scroll-mt-20"
      >
        <div
          className="space-margin-bottom-xl space-margin-top-xl"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <p className="text-md-semibold mb-0">Similar Parts</p>

          <button
            aria-selected={isComparingParts}
            className="button-blank space-padding-left-md space-padding-right-md space-padding-top-xs space-padding-bottom-xs button-compare"
            onClick={handleOnComparingParts}
          >
            <p className="text-sm-semibold p-0 m-0">Compare parts</p>
            <input
              className="space-margin-left-xl space-margin-top-none"
              style={{ alignSelf: "center", cursor: "pointer" }}
              type="checkbox"
              checked={isComparingParts}
              onChange={handleOnComparingParts}
            />
          </button>
        </div>
        <div
          className="space-margin-bottom-xl background-color-gray-light-200"
          style={{ height: "1px" }}
        ></div>
        <SimilarParts
          onNextPage={handleNavigateSimilarPartsToNextPage}
          onPreviousPage={handleNavigateSimilarPartsToPreviousPage}
          isAddingSimilarPartToCart={isLoadingAddPartToCart}
          similarPartsCollection={similarParts}
          selectedParts={selectedPartsToCompare}
          canSelectPart={isComparingParts}
          onAddSimilarPartToCart={handleAddSimilarPartToCart}
          onSelectSimilarPartToCompare={handleOnSelectPartToCompare}
        />
      </section>
    );
  };

  return (
    <div className="product-viewer-v2 vention-ui">
      <Breadcrumbs
        className="product-viewer-v2__header-breadcrumbs tw-breadcrumbs"
        breadCrumbs={breadCrumbs}
      />

      <header className="product-viewer-v2__header">
        <div className="product-viewer-v2__header-container">
          <ImageGallery product={part} />

          <div className="product-viewer-v2__header-details">
            <Header
              product={part}
              productVariantOptions={partVariantsOptions}
            />

            <Accordion
              type="multiple"
              defaultValue={["docs", "specs", "includes"]}
            >
              <AccordionItem value="docs">
                <AccordionTrigger className="product-viewer-v2__accordion-title">
                  Documentations
                </AccordionTrigger>
                <AccordionContent>
                  <TechnicalDocuments part={part} />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="specs">
                <AccordionTrigger className="product-viewer-v2__accordion-title">
                  Parts specifications
                </AccordionTrigger>
                <AccordionContent>
                  <Specifications part={part} />
                </AccordionContent>
              </AccordionItem>

              {includedPartsAndFasteners.length > 0 && (
                <AccordionItem value="includes">
                  <AccordionTrigger className="product-viewer-v2__accordion-title">
                    Included with part
                  </AccordionTrigger>
                  <AccordionContent>
                    <WhatsIncluded
                      includedPartsAndFasteners={includedPartsAndFasteners}
                    />
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>
          </div>

          <Cart
            product={part}
            hasSimilarParts={hasSimilarParts}
            handleOnComparingParts={handleOnComparingParts}
          />
        </div>
      </header>

      <SimilarPartComponent />

      <CompareGadget
        isOpen={isComparingParts}
        onCleanCompare={handleOnCleanComparingParts}
        parts={[
          {
            id: `${part.id}`,
            name: part.name,
            imagePath: part.image_path,
            partNumber: part.part_number,
          } as Partial<PartDTO>,
          ...selectedPartsToCompare,
        ]}
        onCloseComparingParts={() => {
          handleCloseComparingParts();
          setSelectedPartsToCompare([]);
        }}
        onCompare={handleCompare}
        onSelectPart={handleOnSelectPartToCompare}
      />

      <Faqs />

      <CompareModal
        fullPartsToCompare={
          resutlFullCompareParts?.result?.data?.parts?.collection
        }
        isLoadingAddingPartToCart={isLoadingAddPartToCart}
        isOpen={isShowComparePartsModal}
        mainPart={{
          id: `${part.id}`,
          name: part.name,
          partNumber: part.part_number,
        }}
        onAddPartToCart={handleAddSimilarPartToCart}
        onClose={() => {
          handleCloseComparePartsModal();
          handleCloseComparingParts();
          setSelectedPartsToCompare([]);
        }}
        isLoading={resutlFullCompareParts.result.isLoading}
        selectedPartsToCompare={selectedPartsToCompare}
        allPropertyLabels={allPropertyLabels}
      />
    </div>
  );
};

export default Page;
