import React, { useState } from "react";
import I18n from "../../../../../helpers/I18n";
import { ValidatorForm } from "react-form-validator-core";
import InputValidator from "../../../../../components/common/InputValidator";
import { GoogleCaptchaV3Service } from "../../../../../api/Google/captchaV3.service";
import httpClient from "../../../../../api/httpClient";
import Loading from "../../../../../components/common/Loading";
import { camelToSnakeCase } from "../../../../../helpers/StringHelper";
import PhoneCountryInput from "../../../../../components/common/inputs/PhoneCountryInput/PhoneCountryInput";
import { User } from "../../../../../api/Users/Types";
import { Checkbox } from "@/components/ui/checkbox";
import { LocalizedPaths } from "../../../../../types/Common";

interface Props {
  readonly formId: string;
  readonly formActionPath: string;
  readonly localeName: string;
  readonly currentUser: User;
  readonly captchaKey: string;
  readonly year?: number;
  readonly explicitMarketingConsent?: boolean;
  readonly explicitTermsConsent?: boolean;
  readonly featuredImage: string;
  readonly featuredImageSuccess?: string;
  readonly featuredImageAlt: string;
  readonly localizedPaths: LocalizedPaths;
}

const GenericFormDownload: React.FC<Props> = ({
  formId,
  formActionPath,
  localeName,
  currentUser,
  captchaKey,
  year,
  explicitMarketingConsent,
  explicitTermsConsent,
  featuredImage,
  featuredImageSuccess,
  featuredImageAlt,
  localizedPaths,
}) => {
  const FORM_DEFAULT_STATE = {
    firstName: currentUser?.first_name || "",
    lastName: currentUser?.last_name || "",
    email: currentUser?.email || "",
    industry: "",
    profession: "",
    company: "",
    phone: currentUser?.phone || "",
    explicitMarketingConsent: explicitMarketingConsent ? false : null,
    explicitTermsConsent: explicitTermsConsent ? false : null,
  };

  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [form, setForm] = useState(FORM_DEFAULT_STATE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleFormChange = (
    key: string,
    value: string | null | boolean,
  ): void => {
    setForm({ ...form, [key]: value });
  };

  const resetForm = (): void => {
    setForm(FORM_DEFAULT_STATE);
  };

  const submitForm = (formData): void => {
    if (window._mfq) window._mfq.push(["formSubmitAttempt", `#${formId}`]);

    httpClient
      .post(formActionPath, formData)
      .then(() => {
        resetForm();
        setFormSuccess(true);
        window.scrollTo(0, 0);
        if (window._mfq) window._mfq.push(["formSubmitSuccess", `#${formId}`]);
      })
      .catch((error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.standard_error",
            );
        setError(errorMessage);
        if (window._mfq) window._mfq.push(["formSubmitFailure", `#${formId}`]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (event): void => {
    event.preventDefault();

    setIsLoading(true);
    setError("");

    if (explicitTermsConsent && !form.explicitTermsConsent) {
      setError(
        I18n.t(
          "views.landing_pages.marketing_landing_pages.generic_form_download.consent.explicit.terms_error",
        ),
      );
      setIsLoading(false);
      return;
    }

    const formData = Object.keys(form).reduce((object, key) => {
      object[camelToSnakeCase(key)] = form[key];
      return object;
    }, {});

    formData["ga_client_id"] = window.ga_client_id;
    formData["year"] = year;

    GoogleCaptchaV3Service.verifyCaptcha((token) => {
      if (token) {
        formData["g_recaptcha_response"] = token;
      }
      submitForm(formData);
    }, captchaKey);
  };

  if (formSuccess) {
    return (
      <div className="generic-form-download">
        <div className="generic-form-download__cover">
          <img
            src={featuredImageSuccess || featuredImage}
            alt={featuredImageAlt}
          />
        </div>

        <div className="generic-form-download__form generic-form-download__form--success">
          <div className="generic-form-download__form-subtitle">
            {I18n.t(
              `views.landing_pages.marketing_landing_pages.${localeName}.components.form_success.subtitle`,
              {
                year: year,
              },
            )}
          </div>
          <h1 className="generic-form-download__form-heading">
            {I18n.t(
              `views.landing_pages.marketing_landing_pages.${localeName}.components.form_success.heading`,
            )}
          </h1>
          {I18n.t(
            `views.landing_pages.marketing_landing_pages.${localeName}.components.form_success.paragraphs`,
            {
              year: year,
            },
          ).map((paragraph, index) => (
            <p className="generic-form-download__form-paragraph" key={index}>
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="generic-form-download">
      <div className="generic-form-download__cover">
        <img src={featuredImage} alt={featuredImageAlt} />
      </div>

      <div className="generic-form-download__form">
        <h1 className="generic-form-download__form-heading">
          {I18n.t(
            `views.landing_pages.marketing_landing_pages.${localeName}.components.form.heading`,
            { year: year },
          )}
        </h1>
        <p className="generic-form-download__form-paragraph">
          {I18n.t(
            `views.landing_pages.marketing_landing_pages.${localeName}.components.form.paragraph`,
          )}
        </p>
        <ValidatorForm
          id={formId}
          className="generic-form-download__form-container"
          onSubmit={onSubmit}
          instantValidate={false}
        >
          <div className="generic-form-download__form-names">
            <InputValidator
              onChange={(event) =>
                handleFormChange("firstName", event.target.value)
              }
              id="first-name"
              className="form-control"
              name="first-name"
              value={form.firstName}
              validators={["required"]}
              errorMessages={[
                I18n.t(
                  "views.landing_pages.marketing_landing_pages.generic_form_download.required_error",
                ),
              ]}
              type="text"
              label={I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.first_name.label",
              )}
              placeholder={I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.first_name.placeholder",
              )}
            />
            <InputValidator
              onChange={(event) =>
                handleFormChange("lastName", event.target.value)
              }
              id="last-name"
              className="form-control"
              name="last-name"
              value={form.lastName}
              validators={["required"]}
              errorMessages={[
                I18n.t(
                  "views.landing_pages.marketing_landing_pages.generic_form_download.required_error",
                ),
              ]}
              type="text"
              label={I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.last_name.label",
              )}
              placeholder={I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.last_name.placeholder",
              )}
            />
          </div>

          <InputValidator
            onChange={(event) => handleFormChange("email", event.target.value)}
            id="email"
            className="form-control"
            name="email"
            value={form.email}
            validators={["required", "isEmail"]}
            errorMessages={[
              I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.required_error",
              ),
              I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.is_email_error",
              ),
            ]}
            type="text"
            label={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.email.label",
            )}
            placeholder={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.email.placeholder",
            )}
          />

          <InputValidator
            onChange={(event) =>
              handleFormChange("company", event.target.value)
            }
            id="company"
            className="form-control"
            name="company"
            value={form.company}
            validators={["required"]}
            errorMessages={[
              I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.required_error",
              ),
            ]}
            type="text"
            label={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.company.label",
            )}
            placeholder={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.company.placeholder",
            )}
          />

          <InputValidator
            onChange={(event) =>
              handleFormChange("industry", event.target.value)
            }
            id="industry"
            className="form-control"
            name="industry"
            value={form.industry}
            validators={["required"]}
            errorMessages={[
              I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.required_error",
              ),
            ]}
            type="text"
            label={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.industry.label",
            )}
            placeholder={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.industry.placeholder",
            )}
          />

          <InputValidator
            onChange={(event) =>
              handleFormChange("profession", event.target.value)
            }
            id="profession"
            className="form-control"
            name="profession"
            value={form.profession}
            validators={["required"]}
            errorMessages={[
              I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.required_error",
              ),
            ]}
            type="text"
            label={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.profession.label",
            )}
            placeholder={I18n.t(
              "views.landing_pages.marketing_landing_pages.generic_form_download.profession.placeholder",
            )}
          />

          <div>
            <label htmlFor="phone">
              {I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.phone.label",
              )}
            </label>
            <PhoneCountryInput
              className="form-control generic-form-download__form-phone"
              placeholder={I18n.t(
                "views.landing_pages.marketing_landing_pages.generic_form_download.phone.placeholder",
              )}
              phone={form.phone}
              onChange={(phone) => handleFormChange("phone", phone)}
              useCountryCode
              required
            />
          </div>

          <div className="generic-form-download__form-terms">
            {!explicitMarketingConsent && !explicitTermsConsent ? (
              <>
                {I18n.t(
                  "views.landing_pages.marketing_landing_pages.generic_form_download.consent.implicit.terms_prefix",
                )}{" "}
                <a href={localizedPaths.privacy_policy} target="_blank">
                  {I18n.t(
                    "views.landing_pages.marketing_landing_pages.generic_form_download.consent.privacy_policy",
                  )}
                </a>{" "}
                {I18n.t(
                  "views.landing_pages.marketing_landing_pages.generic_form_download.consent.and",
                )}{" "}
                <a href={localizedPaths.terms_of_use} target="_blank">
                  {I18n.t(
                    "views.landing_pages.marketing_landing_pages.generic_form_download.consent.terms_of_use",
                  )}
                </a>{" "}
                {I18n.t(
                  "views.landing_pages.marketing_landing_pages.generic_form_download.consent.implicit.marketing_suffix",
                )}
                .
              </>
            ) : (
              <>
                {explicitTermsConsent ? (
                  <div className="flex flex-row mt-2">
                    <Checkbox
                      id="explicit-terms-consent"
                      onCheckedChange={(checked) => {
                        if (typeof checked === "boolean") {
                          handleFormChange("explicitTermsConsent", checked);
                        }
                      }}
                      checked={form.explicitTermsConsent ?? false}
                    />
                    <label htmlFor="explicit-terms-consent" className="pl-2">
                      {I18n.t(
                        "views.landing_pages.marketing_landing_pages.generic_form_download.consent.explicit.terms_prefix",
                      )}{" "}
                      <a href={localizedPaths.privacy_policy} target="_blank">
                        {I18n.t(
                          "views.landing_pages.marketing_landing_pages.generic_form_download.consent.privacy_policy",
                        )}
                      </a>{" "}
                      {I18n.t(
                        "views.landing_pages.marketing_landing_pages.generic_form_download.consent.and",
                      )}{" "}
                      <a href={localizedPaths.terms_of_use} target="_blank">
                        {I18n.t(
                          "views.landing_pages.marketing_landing_pages.generic_form_download.consent.terms_of_use",
                        )}
                      </a>
                      .
                    </label>
                  </div>
                ) : (
                  <div className="mb-6">
                    {I18n.t(
                      "views.landing_pages.marketing_landing_pages.generic_form_download.consent.implicit.terms_prefix",
                    )}{" "}
                    <a href={localizedPaths.privacy_policy} target="_blank">
                      {I18n.t(
                        "views.landing_pages.marketing_landing_pages.generic_form_download.consent.privacy_policy",
                      )}
                    </a>{" "}
                    {I18n.t(
                      "views.landing_pages.marketing_landing_pages.generic_form_download.consent.and",
                    )}{" "}
                    <a href={localizedPaths.terms_of_use} target="_blank">
                      {I18n.t(
                        "views.landing_pages.marketing_landing_pages.generic_form_download.consent.terms_of_use",
                      )}
                    </a>
                    .
                  </div>
                )}

                {explicitMarketingConsent ? (
                  <div className="flex flex-row mt-2">
                    <Checkbox
                      id="explicit-marketing-consent"
                      onCheckedChange={(checked) => {
                        if (typeof checked === "boolean") {
                          handleFormChange("explicitMarketingConsent", checked);
                        }
                      }}
                      checked={form.explicitMarketingConsent ?? false}
                    />
                    <label
                      htmlFor="explicit-marketing-consent"
                      className="pl-2"
                    >
                      {I18n.t(
                        "views.landing_pages.marketing_landing_pages.generic_form_download.consent.explicit.marketing",
                      )}
                      .
                    </label>
                  </div>
                ) : (
                  <div className="mt-6">
                    {I18n.t(
                      "views.landing_pages.marketing_landing_pages.generic_form_download.consent.implicit.marketing",
                    )}
                    .
                  </div>
                )}
              </>
            )}
          </div>

          {error && (
            <p className="generic-form-download__form-error has-error">
              {error}
            </p>
          )}

          <button className="generic-form-download__form-submit" type="submit">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {I18n.t(
                  `views.landing_pages.marketing_landing_pages.${localeName}.components.form.submit`,
                )}{" "}
                <i className="ti ti-download"></i>
              </>
            )}
          </button>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default GenericFormDownload;
