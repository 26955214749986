import React from "react";
import { PartDTO } from "../../../../../api/Parts/dtos/PartDTO";
import { SimplePartCardV2 } from "../../../../../components/parts/SimplePartCardV2";
import Button from "../../../../../components/vention_ui/shared_components/Button";
import { EmptySimplePartCard } from "../../../../../components/parts/EmptySimplePartCard";

export type CompareGadgetProps = {
  isOpen: boolean;
  parts: Partial<PartDTO>[];
  onCleanCompare: () => void;
  onCloseComparingParts: () => void;
  onCompare: () => void;
  onSelectPart: (part: Partial<PartDTO>) => void;
};

export const CompareGadget: React.FC<CompareGadgetProps> = (
  props: CompareGadgetProps,
) => {
  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="compare-gadget">
      <span className="text-md-semibold">{"Compare up to 3 parts"}</span>
      <div className="compare-gadget__wrapper">
        <div className="compare-gadget__container">
          {props.parts.map((part: Partial<PartDTO>, index: number) => (
            <SimplePartCardV2
              key={index}
              part={part}
              isSelectable={index === 0 ? false : props.isOpen}
              isSelected={index === 0 ? false : true}
              onSelectClicked={props.onSelectPart}
              isPinned={index === 0}
            />
          ))}
          {Array.from({ length: 4 - props.parts.length }).map((_, index) => (
            <EmptySimplePartCard key={index}></EmptySimplePartCard>
          ))}
        </div>
        {props?.parts?.length === 0 && (
          <div className="compare-gadget__spacer"></div>
        )}
        <div className="compare-gadget__divider"></div>

        <button
          style={{ position: "fixed", right: "16px", top: "16px" }}
          className="button-blank"
          onClick={props.onCloseComparingParts}
        >
          <span aria-hidden="true" className="icon-close"></span>
        </button>

        <div className="compare-gadget__action-container space-margin-left-md space-margin-top-md space-margin-right-md">
          <Button
            heirarchy="secondary-gray"
            onClick={props.onCleanCompare}
            size="xl"
            className="space-margin-bottom-md"
          >
            <span className="text-xs-medium">Clear all</span>
          </Button>
          <Button
            disabled={(props?.parts?.length ?? 0) < 2}
            heirarchy="primary"
            size="xl"
            className="space-margin-bottom-md"
            onClick={props.onCompare}
          >
            <span className="text-xs-medium">Compare</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
