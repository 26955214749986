import React from "react";
import { SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS } from "../../constants";
import { PartDTO } from "../../../../../api/Parts/dtos/PartDTO";
import Button from "../../../../../components/common/buttons/Button";

export type AddToCartButtonProps = {
  part: Pick<PartDTO, "id" | "allowCheckout" | "shipmentDelayWeeks">;
  isLoadingAddingPartToCart: { [key: string]: boolean };
  onAddPartToCart: (parIdt: string) => void;
  style?: "primary" | "secondary" | "secondary-gray-outline";
};

export const AddToCartButton: React.FC<AddToCartButtonProps> = (
  props: AddToCartButtonProps,
) => {
  const { part, isLoadingAddingPartToCart, onAddPartToCart } = props;

  const style = props?.style ?? "primary";

  return (
    <>
      {part.allowCheckout &&
      part.shipmentDelayWeeks < SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS ? (
        <Button
          title="Add to Cart"
          className={`vention-ui-button--${style} self-center w-full mt-[16px]`}
          isLoading={isLoadingAddingPartToCart?.[part.id] === true}
          onClick={() => onAddPartToCart(part.id)}
        >
          <span className="text-sm-semibold">Add to Cart</span>
        </Button>
      ) : (
        <Button
          className={`vention-ui-button--${style} self-center w-full mt-[16px]`}
          onClick={() =>
            window.open("/contact/new?choice=technical+support", "_blank")
          }
        >
          <span className="text-sm-semibold">Contact Us</span>
        </Button>
      )}
    </>
  );
};
