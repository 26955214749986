import React, { useState, useEffect } from 'react'
import OnboardingProfileSetUpWrapper from '../../components/onboarding/OnboardingProfileSetUpWrapper'
import UserService from '../../api/Users/Service'
import { TeamPreview, User } from '../../api/Users/Types'
import DesignCategoriesProvider from '../../components/common/providers/DesignCategoriesProvider'
import TeamService from '../../api/Teams/Service'
import OnboardingCreateOrJoinWrapper from './OnboardingCreateOrJoinWrapper'
import OnboardingCreateTeamWrapper from './OnboardingCreateTeamWrapper'
import OnboardingJoinTeamWrapper from './OnboardingJoinTeamWrapper'
import OnboardingInviteToNewTeamWrapper from './OnboardingInviteToNewTeamWrapper'
import OnboardingStepsBar from './OnboardingStepsBar'

const RETURN_TO_URL_WHITELIST = [
  'https://forum.vention.io/auth/oauth2_basic/'
]

interface Props {
  readonly profile: User
  readonly showEmailMarketingConsent: boolean
  readonly invitation_code: string
  existing_teams: ReadonlyArray<TeamPreview>
  companyIdFromInvitation?: number
  platformReferralToken?: string
  returnTo?: string
}

// This is the legacy Onboarding component, which will include only the activation at some point.
// For now, it also includes the team selection with the possibility of inviting other users to the team.
const Onboarding: React.FunctionComponent<Props> = ({
  profile,
  showEmailMarketingConsent,
  invitation_code,
  platformReferralToken,
  returnTo,
  existing_teams,
  companyIdFromInvitation,
}) => {
  const [stepsBar, setStepsBar] = useState({
    onProfileSetup: true,
    onCreateOrJoin: false,
    onCreateTeam: false,
    onInviteToNewTeam: false,
    onJoinTeam: false,
  })

  const [user, setUser] = useState({
    firstName: profile.first_name || '',
    lastName: profile.last_name || '',
    phone: profile.phone || '',
    password: '',
    role: profile.role || '',
    companyUser: profile.company_user || null,
  })

  const [emailMarketingConsent, setEmailMarketingConsent] = useState<undefined | boolean>()

  const [teamMateEmails, setTeamMateEmails] = useState(['', '', ''])
  const [newTeamName, setNewTeamName] = useState('')
  const [teamSelected, setTeamSelected] = useState({
    teamId: null,
    active: false,
  })

  const [errors, setErrors] = useState({})

  const userAlreadyHasTeam = !!profile.company_user?.id

  useEffect(() => {
    if (showEmailMarketingConsent) {
      // marketing consent must be explicitly given if shown
      setEmailMarketingConsent(false)
    }
  }, [showEmailMarketingConsent])

  const createUserProfile = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    try {
      const response = await UserService.activateUserProfile(
        profile.activated,
        user.firstName,
        user.lastName,
        user.phone,
        user.password,
        user.role,
        profile.id,
        invitation_code,
        timeZone,
        emailMarketingConsent,
        platformReferralToken
      )

      setErrors({})

      if (Array.isArray(window.dataLayer)) {
        window.dataLayer.push({
          event: response.ga_event.name,
          event_id: response.ga_event.id,
        })
      }

      return true
    } catch (error) {
      toastr.error('An error occured while creating your profile')
      setErrors(error.response.data.errors)
      return false
    }
  }

  const createNewTeam = async () => {
    try {
      const teamResponse = await TeamService.createTeamSignup(newTeamName, profile.id)
      return teamResponse.teamID
    } catch (error) {
      toastr.error('An error occured while creating your team')
    }
  }

  const inviteToNewTeam = async (teamID, newTeamEmails) => {
    try {
      await TeamService.inviteNewMembersToTeam(
        teamID,
        JSON.stringify(newTeamEmails),
        'onboarding form team'
      )
    } catch (error) {
      toastr.error('An error occured while inviting new members to your team')
    }
  }

  const joinSelectedTeam = async teamToJoin => {
    try {
      await TeamService.joinTeam(teamToJoin, profile.id, 'onboarding form team')
    } catch (error) {
      toastr.error('There was an error joining the selected team')
    }
  }

  const resetStepsToFalse = () => {
    Object.keys(stepsBar).forEach(each => (stepsBar[each] = false))
    setStepsBar({ ...stepsBar })
  }

  const goToCreateOrJoin = () => {
    if (userAlreadyHasTeam) {
      goToFinish()
    } else {
      resetStepsToFalse()
      setStepsBar({ ...stepsBar, onCreateOrJoin: true })
    }
  }

  const goToFinish = () => {
    const destination =
      returnTo && RETURN_TO_URL_WHITELIST.includes(returnTo) ? returnTo : '/onboarding'
    window.location.href = destination
  }

  const goToJoinTeam = () => {
    resetStepsToFalse()
    setStepsBar({ ...stepsBar, onJoinTeam: true })
  }

  const goToCreateTeam = () => {
    resetStepsToFalse()
    setStepsBar({ ...stepsBar, onCreateTeam: true })
  }

  const goToInviteToNewTeam = () => {
    resetStepsToFalse()
    setStepsBar({ ...stepsBar, onInviteToNewTeam: true })
  }

  const createOrJoinTeamInStepsBar =
    !(newTeamName.length > 0) && !stepsBar.onCreateTeam && existing_teams?.length > 0

  const showStepper = !userAlreadyHasTeam && !stepsBar.onProfileSetup

  return (
    <DesignCategoriesProvider>
      <div className='project-titan-auth-modal'>
        {showStepper && (
          <OnboardingStepsBar
            stepsBar={stepsBar}
            goToCreateOrJoin={goToCreateOrJoin}
            goToJoinTeam={goToJoinTeam}
            createOrJoinTeamInStepsBar={createOrJoinTeamInStepsBar}
            allowBackToJoinTeam={existing_teams?.length > 0}
          />
        )}

        {stepsBar.onProfileSetup && (
          <OnboardingProfileSetUpWrapper
            profile={profile}
            goToCreateOrJoin={goToCreateOrJoin}
            user={user}
            setUser={setUser}
            emailMarketingConsent={emailMarketingConsent}
            setEmailMarketingConsent={setEmailMarketingConsent}
            createUserProfile={createUserProfile}
            errors={errors}
          />
        )}
        {stepsBar.onCreateOrJoin && (
          <OnboardingCreateOrJoinWrapper
            profile={profile}
            existing_teams={existing_teams}
            goToJoinTeam={goToJoinTeam}
            goToCreateTeam={goToCreateTeam}
          />
        )}
        {stepsBar.onCreateTeam && (
          <OnboardingCreateTeamWrapper
            goToInviteToNewTeam={goToInviteToNewTeam}
            userFirstName={user.firstName}
            newTeamName={newTeamName}
            setNewTeamName={setNewTeamName}
          />
        )}
        {stepsBar.onJoinTeam && (
          <OnboardingJoinTeamWrapper
            profile={profile}
            joinSelectedTeam={joinSelectedTeam}
            existing_teams={existing_teams}
            setTeamSelected={setTeamSelected}
            teamSelected={teamSelected}
            companyIdFromInvitation={companyIdFromInvitation}
            goToFinish={goToFinish}
          />
        )}
        {stepsBar.onInviteToNewTeam && (
          <OnboardingInviteToNewTeamWrapper
            teamMateEmails={teamMateEmails}
            setTeamMateEmails={setTeamMateEmails}
            createNewTeam={createNewTeam}
            inviteToNewTeam={inviteToNewTeam}
            newTeamName={newTeamName}
            goToFinish={goToFinish}
            referral_code={profile.id}
          />
        )}
      </div>
    </DesignCategoriesProvider>
  )
}

export default Onboarding
