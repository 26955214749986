import React from "react";
import { PartSkeletonCard } from "./PartSkeletonCard";

export type PartSkeletonListProps = {
  readonly count: number;
};
export const PartSkeletonList: React.FC<PartSkeletonListProps> = ({
  count,
}: PartSkeletonListProps) => {
  return (
    <section
      id="part-recommendations-v2"
      className="product-viewer-v2__part-recommendations product-viewer-v2__section space-padding-lg"
    >
      <span className="text-md-semibold">Similar Parts</span>
      <div
        className="space-margin-bottom-xl space-margin-top-xl background-color-gray-light-200"
        style={{ width: "100%", height: "1px" }}
      ></div>
      <div className="product-viewer-v2__card-links">
        {Array(count)
          .fill(0)
          .map((_, index) => (
            <PartSkeletonCard key={index} />
          ))}
      </div>
    </section>
  );
};
