import React from "react";
import { ControllerType, useControllerContext } from "./EngineAndControllerProvider";
import { CategoryFacet } from "./CategoryFacet";
import { PriceFilter } from "./PriceFilter";
import { BrandFacet } from "./BrandFacet";
import { PartCategoryByCode } from "../../../pages/views/part_searches/Types";

export interface FacetListProps {
  partCategoryByCode: PartCategoryByCode;
}

export const FacetList: React.FC<FacetListProps> = (props) => {
  const categoryController = useControllerContext(ControllerType.CategoryFacet);
  const brandController = useControllerContext(ControllerType.BrandFacet);

  return (
    <>
      <CategoryFacet
        controller={categoryController}
        title="Category"
        labels={props.partCategoryByCode}
      />
      <BrandFacet
        controller={brandController}
        title="Brand" 
      />
      <PriceFilter />
    </>
  );
};
