import React from "react";;
import { IconedElement, TreeViewElement } from "@/components/ui/tree-view-api";
import { EventListener } from "./TreeMenuView";
import { FacetValue } from "@coveo/headless";

export type TreeItemProps = {
  elements: (TreeViewElement & { facet?: FacetValue, isSelected?: boolean })[]
};

export const TreeMenuItem: React.FC<TreeItemProps & EventListener> = ({ elements, onClickElement }: TreeItemProps & EventListener) => {
  return (
    <div>
      {elements.map(element => (
        <div key={element.id}>
          {
            (element.children?.length ?? 0) > 0 ? (
              <IconedElement
                key={element.id}
                element={element.name}
                value={element.id}
                className={`bg-white border-none space-between w-full`}
                useIcon
                isSelect={element.isSelected}
                onTextClicked={() => onClickElement(element)}
              >
                <TreeMenuItem
                  key={element.id}
                  aria-label={`folder ${element.name}`}
                  elements={element.children ?? []}
                  onClickElement={onClickElement}
                />
              </IconedElement>
            ) : (
              <IconedElement
                onTextClicked={() => onClickElement(element)}
                key={element.id}
                value={element.id}
                element={element.name}
                isSelect={element.isSelected}
                className={`bg-white border-none w-full`}
              />
            )
          }
        </div>
      ))}
    </div>
  );
};
