import React, { useEffect, useState } from "react";
import {
  GeneralSpecification,
  PartDTO,
} from "../../../../../api/Parts/dtos/PartDTO";
import { TechnicalSpecificationPropertiesDTO } from "../../../../../api/Parts/dtos/TechnicalSpecificationDTO";
import { splitAndCapitalize } from "../../../../../helpers/StringHelper";
import WithTooltip from "../../../../../components/common/WithTooltip";
import { PartSkeletonCard } from "../../../../../components/parts/PartSkeletonCard";
import { FormattedPrice } from "./FormattedPrice";
import { AddToCartButton } from "./AddToCartButton";

const GENERAL_SPECIFICATION_PROPERTY_NAME: (keyof GeneralSpecification)[] = [
  "partNumber",
  "weight",
  "width",
  "height",
  "displayPrice",
  "material",
] as const;

export type CompareModalProps = {
  mainPart: Partial<PartDTO>;
  selectedPartsToCompare: Partial<PartDTO>[];
  fullPartsToCompare?: PartDTO[];
  isOpen: boolean;
  allPropertyLabels?: Record<string, string[]>;
  isLoadingAddingPartToCart: { [key: string]: boolean };
  isLoading: boolean;
  onClose: () => void;
  onAddPartToCart: (part: string) => void;
};

export const CompareModal: React.FC<CompareModalProps> = (
  props: CompareModalProps,
) => {
  const {
    isOpen,
    selectedPartsToCompare,
    mainPart,
    fullPartsToCompare,
    allPropertyLabels,
    isLoadingAddingPartToCart,
    onClose,
    onAddPartToCart,
  } = props;

  const [oldStyle] = useState(document.body.style.overflow);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = oldStyle;
    }

    return () => {
      document.body.style.overflow = oldStyle;
    };
  }, [isOpen]);

  const getProperty = (
    property: string,
    listOfProperties: TechnicalSpecificationPropertiesDTO[],
  ): TechnicalSpecificationPropertiesDTO => {
    return (
      listOfProperties.find((prop) => prop.label === property) ?? {
        label: property,
        spec_name: "",
        value: "",
        unit: "",
      }
    );
  };

  const handleOutClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    if (event?.target?.["className"] === "compare-part-modal") {
      onClose();
    }
  };

  const TechnicalSpecificationComponent = (props: {
    label: string;
    propertyLabels: string[];
    partResult: PartDTO;
  }) => {
    const foundSpec = props.partResult.technicalSpecifications.find(
      (technicalSpec) => technicalSpec.label === props.label,
    );

    return (
      <>
        <table className="compare-part-modal__table compare-part-modal__table__table">
          <tr className="compare-part-modal__table">
            <th className="compare-part-modal__table compare-part-modal__table__header">
              <span className="text-xs-bold"> {props.label}</span>
            </th>
          </tr>
          <tbody>
            {props.propertyLabels?.map((label) => {
              const property = getProperty(label, foundSpec?.properties ?? []);
              return (
                <tr
                  key={property.label}
                  className="compare-part-modal__table  compare-part-modal__table__element"
                >
                  <td className="compare-part-modal__table">
                    <span className="text-xs-medium">
                      {splitAndCapitalize(property.label)}
                    </span>
                    <WithTooltip
                      tooltipClassName="compare-tooltip"
                      label={
                        !property.value
                          ? "N/A"
                          : `${property.value} ${property.unit ?? ""}`
                      }
                    >
                      <span className="text-xs-regular line-clamp-2">
                        {!property.value
                          ? "N/A\n\t"
                          : `${property.value} ${property.unit ?? ""}`.length <
                              21
                            ? `${property.value} ${property.unit ?? ""}\n\t`
                            : `${property.value} ${property.unit ?? ""}`}
                      </span>
                    </WithTooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  if (!isOpen) {
    return null;
  }

  if (props.isLoading) {
    return (
      <div className="compare-part-modal" onClick={handleOutClick}>
        <div className="compare-part-modal__container">
          <div className="compare-part-modal__header">
            <p className="text-sm-bold">
              {"Parts "}
              {`(${selectedPartsToCompare.length})`}
            </p>
            <button
              className="button-blank"
              style={{ position: "relative", right: "20px" }}
              onClick={onClose}
            >
              <span aria-hidden="true" className="icon-close"></span>
            </button>
          </div>
          <div className="compare-part-modal__skeleton-list compare-part-modal__compare-container">
            {Array(selectedPartsToCompare.length + 1)
              .fill(0)
              .map((_, index) => (
                <PartSkeletonCard key={index} />
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="compare-part-modal" onClick={handleOutClick}>
      <div className="compare-part-modal__container">
        <div className="compare-part-modal__header">
          <p className="text-xs-bold">
            {"Parts "}
            {`(${selectedPartsToCompare.length})`}
          </p>
          <button
            className="button-blank"
            style={{ position: "relative", right: "20px" }}
            onClick={onClose}
          >
            <span aria-hidden="true" className="icon-close"></span>
          </button>
        </div>
        <div className="compare-part-modal__compare-container">
          {fullPartsToCompare?.map((partResult: PartDTO) => {
            return (
              <div
                key={partResult.id}
                className="compare-part-modal__part_card"
                aria-selected={`${mainPart.id}` === partResult.id}
              >
                <div className="compare-part-modal__image-container">
                  <img
                    className="product-viewer-v2__card-link-image"
                    src={partResult.imagePath}
                    alt={partResult.name}
                    loading="lazy"
                  />
                </div>
                {`${mainPart.id}` === partResult.id && (
                  <p className="chip-pinned text-sm-medium">Pinned</p>
                )}
                <div
                  style={{ zIndex: 1001 }}
                  className={`mt-2 py-2 sticky top-14 ${mainPart.id === partResult.id ? "bg-slate-200" : "bg-white"}`}
                >
                  <p className="text-xs-semibold line-clamp-2 whitespace-pre-wrap">
                    {`${partResult.name}`.length < 26
                      ? `${partResult?.name}\n\t`
                      : partResult?.name}
                  </p>
                </div>

                <p className="product-viewer-v2__header-price space-margin-top-sm space-margin-left-xxs h-[30px]">
                  {partResult?.displayPrice && (
                    <FormattedPrice
                      price={{
                        amount: partResult.displayPriceRaw.amount,
                        currency_code: partResult.displayPriceRaw.currencyCode,
                        currency_symbol:
                          partResult.displayPriceRaw.currencySymbol,
                      }}
                    />
                  )}
                </p>

                <AddToCartButton
                  part={partResult}
                  isLoadingAddingPartToCart={isLoadingAddingPartToCart}
                  onAddPartToCart={onAddPartToCart}
                />

                <table className="compare-part-modal__table compare-part-modal__table__table">
                  <tr className="compare-part-modal__table">
                    <th className="compare-part-modal__table compare-part-modal__table__header">
                      <p className="text-xs-bold">General Specification</p>
                    </th>
                  </tr>
                  <tbody>
                    {GENERAL_SPECIFICATION_PROPERTY_NAME.map((key) => {
                      const unit =
                        key === "weight"
                          ? "kg"
                          : key === "width" || key === "height"
                            ? "mm"
                            : "";
                      return (
                        <tr
                          className="compare-part-modal__table  compare-part-modal__table__element"
                          aria-selected={key === "name" ? "true" : "false"}
                          key={key}
                        >
                          <td className="compare-part-modal__table">
                            <p className="text-xs-medium">
                              {splitAndCapitalize(key)}
                            </p>
                            <WithTooltip
                              tooltipClassName="compare-tooltip"
                              label={
                                !partResult[key]
                                  ? "N/A"
                                  : `${partResult[key]} ${unit}`
                              }
                            >
                              <p className="text-xs-regular line-clamp-2">
                                {!partResult[key]
                                  ? "N/A\n\t"
                                  : `${partResult[key]} ${unit}`.length < 21
                                    ? `${partResult[key]} ${unit}\n\t`
                                    : `${partResult[key]} ${unit}`}
                              </p>
                            </WithTooltip>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {Object.keys(allPropertyLabels ?? {}).map((keyLabel) => (
                  <TechnicalSpecificationComponent
                    key={keyLabel}
                    label={keyLabel}
                    partResult={partResult}
                    propertyLabels={allPropertyLabels?.[keyLabel] ?? []}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
